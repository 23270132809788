import { captureException } from "@sentry/core";
import { parse, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";

import {
  ssrAvailableBikeProfDates,
  ssrBikeSaleAvailability,
  ssrCheckUser,
  ssrCollectionAvailability,
  ssrDeliveryAvailability,
  ssrGetConfirmationViewer,
  ssrGetPackages,
  ssrGetPricingPackage,
  ssrGetReferralCode,
  ssrGetSubscribedPackages,
  ssrGetSubscriptionQuote,
  ssrOutstandingInvoices,
  ssrSubscriptionCreationState,
  ssrSwapAvailability,
  ssrUpcomingInvoices,
  ssrUserProfile,
} from "../generated/graphql";
import { getApolloClient } from "../utils/apollo";
import { ssrPreviewBuyingYourBike } from "./../generated/graphql";

export const checkEmailHasAccount = async function (email: string) {
  const {
    props: { data: hasEmail },
  } = await ssrCheckUser.getServerPage(
    {
      variables: {
        email,
      },
    },
    getApolloClient()
  );
  return hasEmail;
};

export const getDeliveryAvailability = async ({
  cityId,
  bikeId,
  buzzbikeRetailId,
  postcode,
}: {
  bikeId?: number;
  cityId?: number;
  buzzbikeRetailId?: number;
  postcode?: string;
}) => {
  const {
    props: { data },
  } = await ssrDeliveryAvailability.getServerPage(
    { variables: { bikeId, cityId, buzzbikeRetailId, postcode } },
    getApolloClient()
  );

  return data.availableDeliveryDates.map((date) =>
    parse(date, "yyyy-MM-dd", new Date(), { locale: enGB })
  );
};

export const getBikeSaleAvailability = async ({
  cityId,
  bikeId,
  buzzbikeRetailId,
  postcode,
}: {
  bikeId: number;
  cityId: number;
  buzzbikeRetailId?: number;
  postcode: string;
}) => {
  const {
    props: { data },
  } = await ssrBikeSaleAvailability.getServerPage(
    { variables: { bikeId, cityId, buzzbikeRetailId, postcode } },
    getApolloClient()
  );

  return data.availableBikeSaleDeliveryDates.map((date) =>
    parse(date, "yyyy-MM-dd", new Date(), { locale: enGB })
  );
};

export const getSwapAvailability = async ({
  cityId,
  postcode,
}: {
  cityId?: number;
  postcode?: string;
}) => {
  const {
    props: { data },
  } = await ssrSwapAvailability.getServerPage(
    { variables: { cityId, postcode } },
    getApolloClient()
  );

  return data.availableSwapDates.map((date) =>
    parse(date, "yyyy-MM-dd", new Date(), { locale: enGB })
  );
};

export const getBikeProfAvailability = async ({
  postcode,
}: {
  postcode?: string;
}) => {
  const {
    props: { data },
  } = await ssrAvailableBikeProfDates.getServerPage(
    { variables: { postcode } },
    getApolloClient()
  );

  if (data.availableBikeProfDates.__typename !== "SlotPairList") {
    return [];
  }
  const sortedDates = data.availableBikeProfDates.availableSlots
    .map((date) => parseISO(date.startTime))
    .map(
      (date) =>
        new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    )
    .sort((date1, date2) => date1.getTime() - date2.getTime());

  return sortedDates;
};

export const getCollectionAvailability = async ({
  token,
  buzzbikeRetailId,
  postcode,
}: {
  token: string;
  buzzbikeRetailId?: number;
  postcode?: string;
}) => {
  const {
    props: { data },
  } = await ssrCollectionAvailability.getServerPage(
    { variables: { buzzbikeRetailId, postcode } },
    getApolloClient({ token })
  );
  return data.availableCollectionDates.map((date) =>
    parse(date, "yyyy-MM-dd", new Date(), { locale: enGB })
  );
};

export const getSubscriptionCode = async (id: string, token: string) => {
  const {
    props: { data },
  } = await ssrSubscriptionCreationState.getServerPage(
    { variables: { id } },
    getApolloClient({ token })
  );

  return data.subscriptionCreationState.subscription?.subscriptionCode;
};

export const getReferralCode = async (token: string) => {
  const {
    props: { data },
  } = await ssrGetReferralCode.getServerPage({}, getApolloClient({ token }));

  return data.viewer.subscription?.generatedReferralCode;
};

export const getConfirmationViewer = async (token: string) => {
  const {
    props: { data },
  } = await ssrGetConfirmationViewer.getServerPage(
    {},
    getApolloClient({ token })
  );

  return data.viewer;
};

export const getConfirmationSubscription = async (
  token: string,
  packageSlug: string,
  discountCode: string,
  deliveryDate: string
) => {
  const {
    props: { data },
  } = await ssrGetSubscriptionQuote.getServerPage(
    {
      variables: {
        input: {
          deliveryDate,
          priceOptions: {
            packageSlug,
            discountCode,
          },
        },
      },
    },
    getApolloClient({ token })
  );

  return data.getSubscriptionQuote;
};

export const getPackagesList = async ({
  bikeId,
  cityId,
  packageGroupName,
  discountCode,
}: {
  bikeId?: number | null;
  cityId?: number | null;
  packageGroupName?: string | null;
  discountCode?: string | null;
}) => {
  const {
    props: { data },
  } = await ssrGetPackages.getServerPage(
    {
      variables: {
        bikeId,
        cityId,
        discountCode,
        packageGroupName,
      },
    },
    getApolloClient()
  );

  const { pricingPackages } = data;
  const packages = [
    pricingPackages.defaultPackage,
    ...pricingPackages.otherPackages,
  ];

  return packages;
};

export const getPackage = async (packageSlug: string) => {
  const {
    props: { data },
  } = await ssrGetPricingPackage.getServerPage(
    { variables: { packageSlug } },
    getApolloClient()
  );

  return data.getPricingPackage;
};

export const getUserInfo = async function (token: string) {
  const {
    props: { data },
  } = await ssrUserProfile.getServerPage({}, getApolloClient({ token }));

  return { ...data.viewer };
};

export const getSubscribedPackages = async function (token: string) {
  const {
    props: { data },
  } = await ssrGetSubscribedPackages.getServerPage(
    {},
    getApolloClient({ token })
  );

  return data.getSubscribedPackages;
};

export const getPreviewBuyingYourBike = async function (token: string) {
  const {
    props: { data },
  } = await ssrPreviewBuyingYourBike.getServerPage(
    {},
    getApolloClient({ token })
  );

  return data.previewBuyingYourBike;
};

export const getUpcomingInvoices = async function (token: string) {
  try {
    const {
      props: { data },
    } = await ssrUpcomingInvoices.getServerPage({}, getApolloClient({ token }));

    return data.upcomingInvoice;
  } catch (error) {
    captureException(error);
    return undefined;
  }
};

export const getOutstandingInvoices = async function (token: string) {
  try {
    const {
      props: { data },
    } = await ssrOutstandingInvoices.getServerPage(
      {},
      getApolloClient({ token })
    );

    return data.outstandingInvoices;
  } catch (error) {
    captureException(error);
    return [];
  }
};
