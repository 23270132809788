import { JZ } from "@buzzbike/ui/src/DesignSystem";
import { IconButton, IconButtonProps } from "@chakra-ui/react";

function CustomIconButton(props: IconButtonProps) {
  return (
    <IconButton
      bg={"none"}
      color={JZ.BuzzBlack}
      _active={{ bg: JZ.Background }}
      _hover={{ bg: JZ["Background 2"] }}
      _focus={{ boxShadow: "none" }}
      _focusVisible={{ boxShadow: "outline" }}
      {...props}
    />
  );
}
export default CustomIconButton;
