import { isSameDay } from "date-fns";
import { isEmpty } from "lodash";
import { TestContext } from "yup";

import { BUZZBIKE_E_NAMES } from "../constants";
import { CityFragment } from "./../generated/graphql";
import {
  getBikeProfAvailability,
  getDeliveryAvailability,
  getPackagesList,
  getSwapAvailability,
} from "./ssrDataFetcher";

export const isValidPostCode = (
  postCode?: string,
  postalAreaList?: string | null,
  postalDistrictList?: string | null
) => {
  if (!postCode || !postalAreaList || !postalDistrictList) {
    return false;
  }
  const postalArea = postCode.match(/^[a-zA-Z][a-zA-Z]?/i);
  const postalDistrict = postCode.match(/^[a-zA-Z]+\d\d?/i);

  if (isEmpty(postalArea) && isEmpty(postalDistrict)) {
    return false;
  }

  const extractedPostalArea = postalArea ? postalArea[0] : "";
  const extractedPostalDistrict = postalDistrict ? postalDistrict[0] : "";

  if (
    !postalAreaList.split(" ").includes(extractedPostalArea.toUpperCase()) &&
    !postalDistrictList
      .split(" ")
      .includes(extractedPostalDistrict.toUpperCase())
  ) {
    return false;
  }

  return true;
};

export const checkValidPostCode = (
  postCode?: string,
  context?: TestContext
) => {
  return isValidPostCode(
    postCode,
    context?.parent.validPostcode.postalAreaList,
    context?.parent.validPostcode.postalDistrictList
  );
};

export const getValidPostcode = (
  postCode?: string,
  cities?: CityFragment[]
) => {
  return cities?.find((city) =>
    isValidPostCode(postCode, city.postalAreaList, city.postalDistrictList)
  );
};

export const checkDeliveryAvailability = async (
  date?: Date,
  context?: TestContext
) => {
  const availableDeliveryDates = await getDeliveryAvailability({
    cityId: context?.parent.cityId,
    bikeId: context?.parent.bikeId,
    buzzbikeRetailId: context?.parent.buzzbikeRetailId,
    postcode: context?.parent.delivery_postcode,
  });
  return date
    ? availableDeliveryDates.some((availableDate) => {
        return isSameDay(availableDate, date);
      })
    : false;
};

export const checkBikeSaleAvailability = async (
  date?: Date,
  context?: TestContext
) => {
  return date
    ? context?.parent.availableDates.some((availableDate: number | Date) => {
        return isSameDay(availableDate, date);
      })
    : false;
};

export const checkPlanAvailability = async (
  plan?: string,
  context?: TestContext
) => {
  const packages = await getPackagesList({
    bikeId: context?.parent.bikeId,
    cityId: context?.parent.cityId,
    packageGroupName: "standard-group",
  });

  return plan
    ? packages.some(({ packageSlug }) => {
        return packageSlug === plan;
      })
    : false;
};

export const checkCollectionAvailability = async (
  date?: Date,
  context?: TestContext
) => {
  return date
    ? context?.parent.availableCollectionDates.some(
        (availableDate: number | Date) => {
          return isSameDay(availableDate, date);
        }
      )
    : false;
};

export const checkSwapAvailability = async (
  date?: Date,
  context?: TestContext
) => {
  const availableSwapDates = await getSwapAvailability({
    cityId: context?.parent.cityId,
    postcode: context?.parent.delivery_postcode,
  });

  return date
    ? availableSwapDates.some((availableDate) => {
        return isSameDay(availableDate, date);
      })
    : false;
};

export const checkBikeProfAvailability = async (
  date?: Date,
  context?: TestContext
) => {
  const availableSwapDates = await getBikeProfAvailability({
    postcode: context?.parent.delivery_postcode,
  });

  return date
    ? availableSwapDates.some((availableDate) => {
        return isSameDay(availableDate, date);
      })
    : false;
};

export const checkAvailableDates = async (
  date?: Date,
  context?: TestContext
) => {
  return date
    ? context?.parent.availableDates.some((availableDate: number | Date) => {
        return isSameDay(availableDate, date);
      })
    : false;
};

export const isBikeEZ = (bikeName?: string) => {
  if (!bikeName) return false;
  return BUZZBIKE_E_NAMES.includes(bikeName.toLocaleUpperCase());
};

export const checkPhoneNumber = async (phoneNumber?: string) => {
  return phoneNumber ? !/^[+]44 0/.test(phoneNumber) : false;
};

export const formatPhone = (phoneNumber?: string) => {
  if (!phoneNumber) return;

  return phoneNumber
    .replace(/\s+/g, "")
    .replace(/([+]44|0)(\d{4})(\d)/, "+44 $2 $3");
};
